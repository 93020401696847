import React, { memo, useEffect, useState, useRef } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import ProfileReviewPDF from "../../Pages/ComponentPage/DownloadPdf";

const StickTab = ({ data, sectionRefs, userData, ratings }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    
    const handleTabClick = (index, refKey) => {
        setIsScrolling(true);
        
        if (sectionRefs[refKey]?.current) {
            const offset = 40;
            const elementPosition = sectionRefs[refKey].current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;
            
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
            
            // Set active tab immediately
            setActiveTab(index);
            
            // Reset scrolling flag after animation
            setTimeout(() => {
                setIsScrolling(false);
            }, 600); // Slightly longer timeout to ensure scroll completion
        }
    };

    useEffect(() => {
        if (isScrolling) return;

        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight * 0.2; // Check 20% from top
            
            let newActiveTab = activeTab;
            let closestDistance = Infinity;

            data.forEach((tab, index) => {
                const section = sectionRefs[tab.refKey]?.current;
                if (section) {
                    const { offsetTop } = section;
                    const distance = Math.abs(scrollPosition - offsetTop);
                    
                    // Find the section closest to the scroll position
                    if (distance < closestDistance) {
                        closestDistance = distance;
                        newActiveTab = index;
                    }
                }
            });

            setActiveTab(newActiveTab);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isScrolling, data, sectionRefs]); // Added dependencies

    const pdfComponentRef = useRef();

    const handleDownload = () => {
        pdfComponentRef.current?.downloadPDF(userData, ratings);
    };

    return (
        <>
            <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                <Row className="tab-style-07">
                    <Col lg={12} className="p-0 xs:!px-[15px]">
                        <Nav className="justify-between uppercase font-serif font-medium text-center cursor-pointer md:flex-nowrap md:mb-[72px] sm:mb-[66px] sm:border-b-0">
                            {data.map((item, i) => (
                                <Nav.Item style={{padding:0}} key={i}>
                                    <Nav.Link
                                        className={`block text-darkgray py-[30px] px-[40px] mb-0 transition-all duration-300 ${
                                            activeTab === i ? "border-b-[3px] border-solid" : "border-transparent"
                                        }`}
                                        eventKey={i}
                                        onClick={() => handleTabClick(i, item.refKey)}
                                    >
                                        {item.tabTitle}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                            {data.length > 3 && (
                                <Nav.Item>
                                    <Nav.Link className="block text-darkgray py-[30px] px-[40px] mb-0 transition-all duration-300 cursor-pointer">
                                        <button className="btn-explore-white px-4 py-2 font-bold rounded-md ml-4" onClick={handleDownload}>
                                            <span className="mr-2">Download Report</span>
                                        </button>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Col>
                </Row>
            </Tab.Container>
            <ProfileReviewPDF ref={pdfComponentRef} userDataS={userData} ratingsM={ratings}/>
        </>
    );
};

StickTab.propTypes = {
    data: PropTypes.array.isRequired,
    sectionRefs: PropTypes.object.isRequired,
    userData: PropTypes.object,
    ratings: PropTypes.object
};

export default memo(StickTab);