const FooterData = [
      
    {
        title: "Services",
        submenu: [
            {

                title: "For Individual",
                link: "/individual"
            },
            {
                title: "For Business",
                link: "/business"
            }
        ]
    },
    {
        title: "Quick Link",
        submenu: [
            {
                title: "Terms Of Use",
                link: "/term_policy"
            },
            {
                title: "Privacy",
                link: "/privacy"
            },
            {
                title: "Contact us",
                link: "/page/contact-classic"
            },
        ]
    },
]

export default FooterData