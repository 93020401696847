import React from 'react'

// Libraries
import { Col, Container, Navbar, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AnimatePresence, m } from 'framer-motion'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Parallax } from "react-scroll-parallax";
import { Helmet } from "react-helmet";

// Components
import { fadeIn, fadeInLeft, zoomIn } from '../Functions/GlobalAnimations'
import IconWithText from '../Components/IconWithText/IconWithText'
import FooterStyle from '../Pages/Footer/Footer';
import ProcessStep from '../Components/ProcessStep/ProcessStep'
import TestimonialsCarousel09 from '../Components/TestimonialCarousel/TestimonialsCarousel09'
import Overlap from '../Components/Overlap/Overlap'
import InViewPort from "../Components/InViewPort";
import MainHeader from "../Pages/Header/MainHeader";
import { IMAGE_PATHS } from '../Functions/Constant';
import { useNavigate } from "react-router-dom";
// Data
import { ProcessStepData01 } from '../Components/ProcessStep/ProcessStepData'
import { TestimonialsCarouselData9 } from '../Components/TestimonialCarousel/TestimonialsCarouselData'
import { IconWithTextData_09, ReviewsData } from '../Components/IconWithText/IconWithTextData'



const IconWithTextData = [
  {
    icon: "fas fa-user-check text-[40px]  yellow-class",
    title: "You Get Rated, Not Just Reviewed",
    content: "Be the first to build your career with verified ratings from real colleagues and employers.",
  },
  {
    icon: "solid-icon-File text-[40px]  yellow-class",
    title: "No More Traditional Resumes",
    content: "Show your true strengths through a smart profile powered by data, not just claims.",
  },
  {
    icon: "solid-icon-Globe text-[40px]  yellow-class",
    title: "Your Reputation Travels With You",
    content: "Take your ratings and profile wherever you goyour career story stays yours.",
  },
  {
    icon: "solid-icon-Business-Man text-[40px]  yellow-class",
    title: "Smarter Hiring for Everyone",
    content: "Help employers make better decisions with clear, structured performance insights.",
  }
]


const Home = (props) => {
  const navigate = useNavigate();
  const handleReviewClick = () => {
    const jwtTokenJobcritic = localStorage.getItem("jwt_token_jobcritic"); // Check user_id in localStorage
    if (jwtTokenJobcritic) {
      navigate("/write_review"); // If logged in, go to review form
    } else {
      navigate("/login"); // If not logged in, go to login
    }
  };
  return (
    <div style={props.style}>
      {/* <SideButtons /> */}
      {/* Header Start */}
      <MainHeader />
      {/* Header End */}

      {/* Section Start */}
      <Helmet>
        <meta
          name="description"
          content="JobCritics: World’s first platform for individual ratings. Build your reputation for free. Boost your career now!"
        />
      </Helmet>
      <section className="py-[160px] overflow-hidden cover-background sm:py-[80px]" style={{ backgroundImage: `url('${IMAGE_PATHS.FIRSTBG} ')` }}>
        <Container>
          <Row className="items-center h-[720px] lg:h-[500px] sm:h-[550px] xs:h-[750px] overflow-hidden">
            <Col xl={5} md={6} className="sm:mb-[40px] overflow-hidden">
              <h2 className="heading-5 font-serif font-semibold  uppercase mb-[5px] black-class"> Build Your Professional Reputation </h2>
              <h3 className="font-serif font-bold   mb-[25px] tracking-[-1px] yellow-class">World’s First Platform for Individual Ratings</h3>
              <p className="w-[90%] text-lg mb-[45px] leading-[30px] xs:text-xmd black-class">Help your colleagues and employees build strong professional reputations.</p>

              <Button
                className="btn-explore-white bto"
                onClick={handleReviewClick}
              >
                Start Your Free Profile Now
              </Button>

            </Col>
            <Col xl={7} md={6} className="text-right">
              <img width="583px" height="608px" className="inline-block" src={IMAGE_PATHS.FIRST} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
      <Helmet>

        <meta
          name="description"
          content="JobCritics empowers you with verified reputations, easy hiring, anonymous reviews, and secure ratings. Join now!"
        />
      </Helmet>
      {/* Section Start */}
      <section className="pt-[25px] pb-[8%] border-b border-[#ededed] overflow-hidden sm:pt-[50px] sm:pb-32">
        <Container>
          <Row className="justify-center xs:mb-16">
            <Col lg={5} sm={7} className="text-center font-serif mb-[60px] md:mb-[35px] sm:mb-16 xs:mb-0">
              <span className="font-semibold uppercase  yellow-class block tracking-[1px] mb-[20px] sm:mb-[10px]">Why Choose JobCritics? We Put You First</span>
              <h2 className="heading-5 mx-auto font-medium text-[#131312] w-[90%] xs:w-full">The Only Platform for Verified Reputations and Smarter Hiring.</h2>
              <span className="font-semibold   block tracking-[1px] mb-[20px] sm:mb-[10px]">We empower individuals and employers with a trusted, secure rating system.</span>
            </Col>
          </Row>
          <ProcessStep grid="row-cols-1 row-cols-sm-2 row-cols-lg-4 gap-y-20" theme="process-step-style-04" data={ProcessStepData01} animation={fadeIn} />
        </Container>
      </section>
      {/* Section End */}

      {/* Lazy Load HTML */}
      <InViewPort>
        {/* Section Start */}
        <Helmet>
          <meta
            name="description"
            content="JobCritics: World’s first platform for innovative hiring tech, verified profiles, and AI insights. "
          />
        </Helmet>
        <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[50px]">
          <Container>
            <Row className="items-center justify-center">
              <Col md={6} xs={10} className="text-center sm:mb-[30px]">
                <m.img width="446px" height="509px" className="md:pr-[30px] mx-auto" src={IMAGE_PATHS.FOURTH} alt="" {...{ ...fadeIn, transition: { delay: 0.2 } }} />
              </Col>
              <m.div className="col-10 col-xl-4 col-lg-5 offset-lg-1 col-md-6 ps-lg-0 text-left md:text-start sm:text-center" {...fadeIn}>
                <span className="font-serif font-semibold  yellow-class tracking-[1px] inline-block uppercase mb-[20px] sm:mb-[10px]">What is JobCritics?</span>
                <h2 className="heading-5 font-serif font-medium text-[#131312] tracking-[-1.5px]" >What is JobCritics? A Platform for Professional Reputation</h2>
                <span className="font-serif font-semibold   tracking-[1px] inline-block  mb-[20px] sm:mb-[10px]">The World’s First Platform for Innovative Hiring Technology</span>
                <p className="mb-[40px]">JobCritics is a professional reputation platform that transforms careers with innovative hiring technology. Individuals create verified profiles using real performance data from employers and colleagues. Employers get instant, one-click credential checks. With AI-powered insights and peer-reviewed ratings, JobCritics replaces traditional resumes with a smarter, more trusted way to hire and grow.</p>

                <Button
                  className="btn-explore-white bto"
                  onClick={handleReviewClick}
                >
                  Start Your Profile Now
                </Button>

              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <Helmet>
          <meta
            name="description"
            content="JobCritics: Create a profile, get ratings, generate an AI report, and review others for better opportunities!"
          />
        </Helmet>
        <section className="h-[550px] lg:h-[500px] sm:h-auto py-[130px] overflow-hidden cover-background lg:py-[90px] sm:py-[50px]" style={{ backgroundImage: `url(${IMAGE_PATHS.SECONDBG}` }}>
          <Container>
            <Row className="items-center justify-between">
              <m.div className="col-xl-3 col-lg-4 text-left md:text-center md:mb-[40px]" {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                <span className="font-serif font-medium yellow-class tracking-[1px] inline-block uppercase mb-[10px] font-semibold">How It Works?</span>
                <h2 className="font-serif font-semibold text-gradient bg-gradient-to-r from-[#131312] via-[#131312] to-[#131312] mb-0 text-[25px] md:text-[25px] lg:text-[40px] leading-[1.2]  md:text-left">
                  How Does ,<br />JobCritics Work?
                </h2>
                <span className="font-serif font-semibold   tracking-[1px] inline-block mb-[20px] sm:mb-[10px]">More Reviews,<br /> More Opportunities</span>

              </m.div>
              <Col lg={9} className="text-center">
                <IconWithText grid="row-cols-1 row-cols-md-4 row-cols-sm-2 items-center" theme="icon-with-text-09" data={IconWithTextData_09} animation={zoomIn} animationTransition="circOut" animationDuration={0.5} animationDelay={0.1} />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <Helmet>
          <meta
            name="description"
            content="JobCritics: Build your career with verified ratings, smart profiles, portable reputation, and better hiring. Start now!"
          />
        </Helmet>
        <section className="py-[130px] overflow-hidden lg:py-[90px] md:pb-[120px] sm:pt-0 sm:py-[50px] lg:pt-[20px]">
          <Container>
            <Row className="items-center justify-center mb-[90px] md:mb-0">
              <Col lg={6} md={10} className="text-left md:mb-[30px]">
                <m.img width="548px" height="471px" src={IMAGE_PATHS.SECOND} alt="" {...fadeIn} />
              </Col>
              <Col lg={{ span: 5, offset: 1 }} md={10} className="seoagency-iconwithtext">
                <span className="font-serif font-semibold  yellow-class tracking-[1px] inline-block uppercase mb-[20px] sm:mb-[10px]">What Makes JobCritics Different?"</span>
                <h2 className="heading-5 font-serif font-medium text-[#131312] tracking-[-1.5px] mb-[5px]" >What Makes JobCritics Different?</h2>
                <span className="font-serif font-semibold   tracking-[1px] inline-block  mb-[8px] sm:mb-[10px]">World’s First Rating & Review Platform for Individuals</span>
                <IconWithText grid="row-cols-1 row-cols-lg-1" theme="icon-with-text-01 m-0 py-[40px] border-b border-mediumgray last-of-type:border-0" data={IconWithTextData} animation={fadeInLeft} animationDelay={0.2} />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section className="overflow-visible pb-[100px] cover-background sm:py-[50px]" style={{ backgroundImage: `url('https://lithoreact.themezaa.com/assets/img/webp/home-seo-agency-bg-03.webp')` }}>
          <Container>
            <Row>
              <Overlap>
                <m.div className="bg-gradient-to-tr from-[#FFBF00] via-[#FFBF00] to-[#FFDF80] rounded-[5px] py-[55px] md:p-[40px] section-wrappers" {...fadeIn}>
                  <Row className="justify-center items-center">
                    <Col lg={8} className="md:mb-[30px]">
                      <h1 className="heading-4 font-serif font-medium  mb-0  md:text-center black-class">Build Your Reputation with Verified Ratings!</h1>
                    </Col>
                    <Col lg={3} sm={10} xs={12}>
                      <div className="elative subscribe-style-06">
                        <Button className="btn-explore-white bto" onClick={handleReviewClick} >Get Started</Button>
                      </div>
                    </Col>
                  </Row>
                </m.div>
              </Overlap>
            </Row>
            <Row className="justify-center mt-40">
              <Col xl={6} lg={7} md={8} className="relative">
                <TestimonialsCarousel09
                  data={TestimonialsCarouselData9}
                  animation={fadeIn}
                  carouselOption={{
                    slidesPerView: 1,
                    loop: true,
                    navigation: true
                  }}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}
        {/* Section Start */}
        <Helmet>
          <meta
            name="description"
            content="JobCritics: Transform hiring with verified reviews, easy checks, and instant insights. Start smarter now!"
          />
        </Helmet>
        <section className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] xs:py-[50px]">
          <Container>
            <Row className="justify-center flex">
              <m.div className="text-center mb-20 md:mb-12 col-lg-6 col-sm-8" {...fadeIn}>
                <span className="text-xmd mb-[15px] font-serif block w-full yellow-class font-semibold">Transform Your Hiring Process</span>
                <h5 className="font-serif text-darkgray font-medium mb-8 sm:w-full">Hire Smarter, Faster, and with Confidence</h5>
              </m.div>
              <Col xs={12} md={9} lg={12}>
                <IconWithText
                  grid="row-cols-1 row-cols-md-1 row-cols-lg-2 gap-y-[15px]"
                  theme="icon-with-text-02 about-us-icon-with-text hove-cls"
                  data={ReviewsData}
                  animation={fadeIn}
                  animationDelay={0.1}
                />
              </Col>
            </Row>
          </Container>
        </section >
        {/* Section End */}

        <m.section className="py-[40px] pb-[120px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] overflow-hidden bg-lightgray" {...fadeIn}>
          <Container>
            <Row className="justify-center text-center font-serif font-medium text-darkgray">
              <Col xl={12} lg={8} md={10}>
                <h2 className="-tracking-[2px] mb-[50px] md:mb-[40px] xs:w-[98%] xs:mx-auto">Start Hiring Smart Hiring!</h2>
                <Button className="btn-explore-white bto !rounded-[15px]" onClick={handleReviewClick}>
                  Get started
                </Button>
              </Col>
            </Row>
          </Container>
        </m.section>


        {/* Section End */}

        {/* Section Start */}
        <FooterStyle theme="light" className="bg-black text-[#828282] seo-agency-footer_06" />
        {/* Section End */}
      </InViewPort>
    </div>
  )
}

export default Home
